.NavsMenus {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 10px 30px;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.105); */
    position: relative;
    z-index: 11;
  }
  .NavsMenus-logo {
    display: block;
  }
  .NavsMenus-logo img {
    display: block;
    height: 30px;
  }
  .NavsMenus .navs {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 50px;
  }
  .NavsMenus .navs a {
    margin: 0 15px;
    position: relative;
    padding: 10px 0;
    display: block;
    transition: all 0.3s;
    border-radius: 10px;
    white-space: nowrap;
    text-decoration: none;
    color: white;
    line-height: 1.3em;
  }
  .NavsMenus .navs a:hover, .NavsMenus .navs a:active, .NavsMenus .navs a.activeColor {
    color: #18D69A;
  }
  .NavsMenus .navs a .navBadge {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 2px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: red;
  }
  .NavsMenus .navs .linkIcon {
    display: inline-block;
    width: 15px;
    height: 15px;
    vertical-align: middle;
    opacity: 0.3;
    margin-left: 2px;
    position: relative;
    top: -2px;
  }
  .NavsMenus .navs .linkIcon img, .NavsMenus .navs .linkIcon svg {
    width: 100%;
    height: 100%;
  }
  .NavsMenus .navs .subBox {
    position: relative;
  }
  .NavsMenus .navs .subBox a {
    cursor: pointer;
  }
  .NavsMenus .navs .subBox:hover .subList {
    display: block;
  }
  .NavsMenus .navs .subBox .subList {
    position: absolute;
    left: 0;
    top: 100%;
    background: #3c3c3c;
    padding: 10px;
    border-radius: 10px;
    padding: 0 10px;
    text-wrap: nowrap;
    display: none;
  }
  .NavsMenus .navs .subBox .subList a {
    display: block;
    margin: 10px 0;
    padding: 5px 10px;
  }
  .NavsMenus .navs .subBox .subList a:hover, .NavsMenus .navs .subBox .subList a.activeColor {
    background: rgba(0, 0, 0, 0.483);
    border-radius: 10px;
  }
  .NavsMenus .navs .subBox .subList a:hover small, .NavsMenus .navs .subBox .subList a.activeColor small {
    color: white;
  }
  .NavsMenus .navs .subBox .subList a span {
    display: block;
  }
  .NavsMenus .navs .subBox .subList a small {
    display: block;
    opacity: 0.3;
  }
  .ComingSoon {
    cursor: not-allowed;
    /* color: #7d7d7d; */
  }
  @media (max-width: 768px) {
    .NavsMenus {
      padding: 12px 0px;
      z-index: 11;
      justify-content: space-between;
    }
    .NavsMenus-logo {
      display: block;
    }
    .NavsMenus .menuIcon {
      height: 30px;
      margin-left: 20px;
    }
    .NavsMenus .navs {
      position: fixed;
      left: 0;
      top: 0;
      height: 100vh;
      width: 100vw;
      z-index: 111;
      background: rgba(0, 0, 0, 0.733);
      backdrop-filter: blur(10px);
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      display: block;
      padding-left: 0;
      padding-top: 50px;
    }
    .NavsMenus .navs a {
      margin: 20px;
      padding: 15px;
      font-size: 20px;
    }
    .NavsMenus .navs .subList {
      z-index: 111;
      margin: 0 30px;
      width: calc(100vw - 60px);
      padding: 15px !important;
    }
    .NavsMenus .navs .closeIcon {
      width: 40px;
      height: 40px;
      background: url("./close.svg") no-repeat center;
      background-size: 80%;
      right: 0;
      z-index: 11;
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
  